<template>
  <v-row justify="center">
    <v-overlay
      :z-index="zIndex"
      :opacity="opacity"
      :value="overlay"
      color="#ffffff"
    >
      <v-progress-circular
        :size="180"
        :width="7"
        color="#6f6f6f"
        indeterminate
        ></v-progress-circular>
        <v-img class="center blink" src="../../assets/img/usa-esta-icon.png" width="100" height="100"></v-img>
    </v-overlay>
  </v-row>
</template>

<script>
  export default {
    data: () => ({
      overlay: true,
      opacity: 1,
      zIndex: 0,
    }),
  }
</script>

<style scoped>
.close {
    position: absolute;
    left: 600px;
    top: -150px;
}
.center {
    position: absolute;
    top: 40px;
    left: 40px;
}
@-webkit-keyframes blinker {
  from {opacity: 1.0;}
  to {opacity: 0.0;}
}
.blink{
	text-decoration: blink;
	-webkit-animation-name: blinker;
	-webkit-animation-duration: 0.6s;
	-webkit-animation-iteration-count:infinite;
	-webkit-animation-timing-function:ease-in-out;
	-webkit-animation-direction: alternate;
}
</style>
